import React from 'react'
import Header from '../component/header'

export default function Contact () {
  return (
    <div>
            <Header/>

      Contact
    </div>
  )
}
