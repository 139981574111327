import React from 'react'
import { Link } from 'react-router-dom';
import './style.css';
const FloatArrayPrinter = ({ datachannels }) => {
  
  return (
    <div>
      <div class="section">
        <div class="container">
            <div class="section-header">
            Last Added
            </div><div class="datarow">
           
            <ul>
        {datachannels.map((data) => (
          
          <li > <Link to={'/TVVideoPlayer/'+ data.id}>
               <a href='./play/?playid={$vid}'  class='movie-item'>
                <img src={data.icon} alt=''/>
                <div class='movie-item-content'>
                    <div class='movie-item-title'>
                    {data.name}
                    </div>
                    <div class='movie-infos'>
                       dlgfree
                       
                    </div>
                </div>
            </a>
      
      
      
      
          </Link>
         </li>
        ))}
      </ul>
           </div>
        </div>
    </div>
      
    </div>
  );
};

// Example usage
const test1 = () => {
  const channels = [
    {id:914406, name: 'Channel one', icon:'https://www.lyngsat.com/logo/tv/cc/channel-one-lk.png' },
    {id:248187, name: 'Rupavahini', icon:'' },
    {id:364698, name: 'Nethra TV', icon:'' },
    {id:425550, name: 'ITN', icon:'' },
    {id:448726, name: 'Vasantham TV', icon:'' },
    {id:468728, name: 'TV Derana', icon:'' },
    {id:292042, name: 'Swarnawahini', icon:'' },
    {id:232468, name: 'Sirasa TV', icon:'' },
    {id:188470, name: 'Shakthi TV', icon:'' },
    {id:163672, name: 'TV1', icon:'' },
    {id:733926, name: 'Hiru TV', icon:'' },
    {id:599372, name: 'TNL', icon:'' },
    {id:813924, name: 'ART Television', icon:'' },
    {id:219127, name: 'AdaDerana 24x7', icon:'' },
    {id:345695, name: 'Siyatha TV', icon:'' },
    {id:733780, name: 'Haritha TV', icon:'' },
    {id:97247, name: 'TV Didula', icon:'' },
    {id:153780, name: 'Ridee TV', icon:'' },
    {id:760869, name: 'Citi Hitz', icon:'' },
    {id:743138, name: 'Supreme TV', icon:'' },
    {id:842687, name: 'Rangiri Sri Lanka', icon:'' },
    {id:744223, name: 'Hi TV', icon:'' },
    {id:226861, name: 'Pragna TV', icon:'' },
    {id:686595, name: 'Damsathara TV', icon:'' },
    {id:765573, name: 'Jaya TV', icon:'' },
    {id:943396, name: 'Buddhist TV', icon:'' },
    {id:503907, name: 'Shraddha TV', icon:'' },
    {id:281820, name: 'GOD TV / Swarga TV', icon:'' },
    {id:390092, name: 'EWTN / Verbum', icon:'' },
    {id:432761, name: 'Al Jazeera', icon:'' },
    {id:419361, name: 'ABC Australia', icon:'' },
    {id:653435, name: 'BBC World', icon:'' },
    {id:84418, name: 'CGTN', icon:'' },
    {id:667199, name: 'CNN', icon:'' },
    {id:470862, name: 'NDTV', icon:'' },
    {id:974280, name: 'France 24', icon:'' },
    {id:177185, name: 'EURO News', icon:'' },
    {id:969512, name: 'Bloomberg', icon:'' },
    {id:784407, name: 'CNBC', icon:'' },
    {id:594084, name: 'B4U Music', icon:'' },
    {id:232113, name: 'VH1', icon:'' },
    {id:112164, name: 'Channel C', icon:'' },
    {id:485682, name: 'Sun Music', icon:'' },
    {id:675548, name: 'Zing TV', icon:'' },
    {id:285310, name: 'Da Vinci', icon:'' },
    {id:411671, name: 'Cheebies', icon:'' },
    {id:681420, name: 'Cartoon Network', icon:'' },
    {id:313901, name: 'A Plus TV', icon:'' },
    {id:963105, name: 'Nickelodeon', icon:'' },
    {id:938966, name: 'Baby TV', icon:'' },
    {id:620285, name: 'Disney Jr', icon:'' },
    {id:57746, name: 'Nick JR', icon:'' },
    {id:684598, name: 'BBC Earth', icon:'' },
    {id:477686, name: 'NGC Wild', icon:'' },
    {id:467842, name: 'Animal Planet', icon:'' },
    {id:557829, name: 'Discovery', icon:'' },
    {id:696935, name: 'Discovery Science', icon:'' },
    {id:142120, name: 'TLC', icon:'' },
    {id:394601, name: 'NAT Geo', icon:'' },
    {id:97252, name: 'History TV 18', icon:'' },
    {id:924264, name: 'Travel XP', icon:'' },
    {id:975687, name: 'HGTV', icon:'' },
    {id:91381, name: 'Food Network', icon:'' },
    {id:354651, name: 'Sony Sports 1', icon:'' },
    {id:869663, name: 'Ten Cricket', icon:'' },
    {id:981559, name: 'Sony Sports 2', icon:'' },
    {id:757246, name: 'Eurosport', icon:'' },
    {id:599747, name: 'Star Sports 2', icon:'' },
    {id:372043, name: 'HBO Signature', icon:'' },
    {id:554312, name: 'HBO Family', icon:'' },
    {id:677956, name: '&flix', icon:'' },
    {id:702649, name: 'HITS Movies', icon:'' },
    {id:182561, name: 'SONY PIX', icon:'' },
    {id:781427, name: 'Hits', icon:'' },
    {id:35924, name: 'ZEE Cafe', icon:'' },
    {id:419827, name: 'WarnerTV', icon:'' },
    {id:411839, name: 'AXN', icon:'' },
    {id:721605, name: 'Colors Infinity', icon:'' },
    {id:547614, name: 'Fashion TV', icon:'' },
    {id:525536, name: 'FOX Life', icon:'' },
    {id:439799, name: 'Comedy Central', icon:'' },
    {id:886940, name: 'Arirang TV', icon:'' },
    {id:964444, name: 'Star Bharat', icon:'' },
    {id:46534, name: 'SONY Set', icon:'' },
    {id:631077, name: 'SET Max', icon:'' },
    {id:170794, name: 'Star Gold', icon:'' },
    {id:667378, name: 'Colors', icon:'' },
    {id:649106, name: 'Star Plus', icon:'' },
    {id:794702, name: 'B4U Movies', icon:'' },
    {id:731520, name: 'Star Gold Romance', icon:'' },
    {id:231916, name: 'ZEE Cinema', icon:'' },
    {id:142092, name: 'Colors Tamil', icon:'' },
    {id:53871, name: 'Sun TV', icon:'' },
    {id:705785, name: 'KTV', icon:'' },
    {id:527510, name: 'Star Vijay', icon:'' },
    {id:910138, name: 'Kalaignar TV', icon:'' },
    {id:16960, name: 'ZEE Tamil', icon:'' },
    {id:473977, name: 'Sirippoli TV', icon:'' },
    {id:174849, name: 'Guru TV', icon:'' },
    {id:238748, name: 'Nenasa Sinhala Grade 1-5', icon:'' },
    {id:214110, name: 'Nenasa Tamil Grade 1-5', icon:'' },
    {id:465498, name: 'Nenasa Sinhala Grade 6-9', icon:'' },
    {id:107262, name: 'Nenasa Tamil Grade 6-9', icon:'' },
    {id:605191, name: 'Nenasa Sinhala Grade O/L', icon:'' },
    {id:816410, name: 'Nenasa Tamil Grade O/L', icon:'' },
    {id:955956, name: 'Nenasa Sinhala Grade A/L', icon:'' },
    {id:114641, name: 'Nenasa Tamil Grade A/L', icon:'' },
    {id:404720, name: 'Discovery HD World', icon:'' },
    {id:205456, name: 'Animal Planet HD', icon:'' },
    {id:672152, name: 'AXN HD', icon:'' },
    {id:291678, name: 'ROCK Entertainment HD', icon:'' },
    {id:339372, name: 'Star Movies Select HD', icon:'' },
    {id:760299, name: 'HBO HD', icon:'' },
    {id:662507, name: 'Star Movies HD', icon:'' },
    {id:565762, name: 'Cinema World HD', icon:'' },
    {id:527873, name: 'CineMax HD', icon:'' },
    {id:319292, name: 'HBO Hits HD', icon:'' },
    {id:199629, name: 'Sri Dalada Maligawa Telecast', icon:'' },
    {id:763087, name: 'Star Sports HD 1', icon:'' },
    {id:677135, name: 'Star S Select HD 1', icon:'' },
    {id:127757, name: 'Star S Select HD 2', icon:'' },
    {id:721272, name: 'Sony Sports 2 HD', icon:'' },
    {id:610885, name: 'Sony Sports 5 HD', icon:'' },
    {id:346708, name: 'Premier Sports HD', icon:'' },
    {id:864716, name: 'Papare HD', icon:'' },
    {id:366342, name: 'Star Sports 1', icon:'' },
    {id:436398, name: 'Star Sports 1', icon:'' },
    

  ];
  return <FloatArrayPrinter datachannels={channels}/>;
};

export default test1;
