import React from 'react'
import Header from "../component/header";

export default function about() {
  return (
    <div>
            <Header/>

      <h1>contact page</h1>
    </div>
  )
}
