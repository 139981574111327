
import React, { useState } from "react"
import axios from "axios"
import { useNavigate, Link } from "react-router-dom"
import Header from "../component/header";


function Login() {
    const history=useNavigate();

    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')

    async function submit(e){
        e.preventDefault();

        try{

            await axios.post("https://new2-2ag.pages.dev/",{
                email,password
            })
            .then(res=>{
                if(res.data==="exist"){
                    alert("User already exists")
                }
                else if(res.data==="notexist"){
                    history("/",{state:{id:email}})
                }
            })
            .catch(e=>{
                alert("wrong details")
                console.log(e);
            })

        }
        catch(e){
            console.log(e);

        }

    }


    return (
        <div className="login">
                  <Header/>

            <div className="singarea">
            <h1>Signup</h1>

<form action="POST">
    {/* <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="Email"  />
    <input type="password" onChange={(e) => { setPassword(e.target.value) }} placeholder="Password" /> */}
    
    <div class="input-box">
        <span class="icon"><ion-icon name="mail"></ion-icon></span>

        <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="Email"  />
          <label>Email/Username</label>
     </div>  
<div class="input-box">
    <span class="icon"><ion-icon name="lock-closed"></ion-icon></span>
    <input type="password" onChange={(e) => { setPassword(e.target.value) }} placeholder="Password" />
    <label>Password</label>
</div> 
<input class="btn" name='submit'  type="submit" onClick={submit} />


</form>

<br />
<p>OR</p>
<br />

<Link to="/Login">Login Page</Link>
            </div>
          

        </div>
    )
}

export default Login
