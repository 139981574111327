import React from 'react';
import { useParams } from 'react-router-dom';

// Make sure your component name starts with an uppercase letter
const Test2 = () => {
  // Use the useParams hook within a React function component
    


  const { id } = useParams();
  const arrayOfArrays = [
    { id: 1, name: 'Array 1' },
    { id: 2, name: 'Array 2' },
    { id: 3, name: 'Array 3' },
  ];

  const specificId = 2;

  const foundArray = arrayOfArrays.find(array => array.id === specificId);

  
  // finding the object whose id is '3'
  
  // printing object on the console
  return (
    <div>
      <p>Param ID: {id}</p>
      {foundArray ? (
        <div>
          <p>Found Array:</p>
          <p>ID: {foundArray.id}</p>
          <p>Name: {foundArray.name}</p>
        </div>
      ) : (
        <p>Array not found</p>
      )}
    </div>
  );
};

export default Test2;
