

// export default VideoPlayer;
import React,  { useEffect }from 'react';
import { useParams} from 'react-router-dom';
import './css/player.css'
import './css/skin.css'
// Make sure your component name starts with an uppercase letter
const VideoPlayer = () => {
  //select play id
  const { id } = useParams();
  const data =[
  
  
     {
       id: 914406,
       name: 'Channel one',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch001/output/index.mpd',
       key: '290634b85d663603c518d270c92ede1e',
       kid: '5803f3c583da2cbc8db8c97db5ca0f20',
       bg:''
  },
  
  {
       id: 248187,
       name: 'Rupavahini',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch002/output/index.mpd',
       key: '4cacbaffdec7470afd62c439f40e4696',
       kid: 'ec6c1a67d677babca9378f26ebb1d388',
       bg:''
  },
  
  {
       id: 364698,
       name: 'Nethra TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch003/output/index.mpd',
       key: '3c0d3e54f53570780df4a55aca6fd522',
       kid: 'ca16bcdf19417733051614aca488c2f9',
       bg:''
  },
  
  {
       id: 425550,
       name: 'ITN',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch004/output/index.mpd',
       key: '64959ae30623394f0967b88331a98c7b',
       kid: '4bbe22bf55839086d322845db6f3e923',
       bg:''
  },
  
  {
       id: 448726,
       name: 'Vasantham TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch005/output/index.mpd',
       key: 'e54bfb9274d00ccf49af3cbb3a1f694e',
       kid: '3f4fb1fa044ec8ccd83fce01147a3ab3',
       bg:''
  },
  
  {
       id: 468728,
       name: 'TV Derana',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch006/output/index.mpd',
       key: 'e1625dcb5261cd0ad5e85910b78ee501',
       kid: 'bafb1f53472647d230609f595685fe58',
       bg:''
  },
  
  {
       id: 292042,
       name: 'Swarnawahini',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch007/output/index.mpd',
       key: '5895f1864e173964ebb77f3bb9c49575',
       kid: '6cd1a07908c73d65d949cea91bf287f9',
       bg:''
  },
  
  {
       id: 232468,
       name: 'Sirasa TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch008/output/index.mpd',
       key: '90baab6ab91d869e124a31f4b4174bc6',
       kid: '0c4dfd9a82658bd152d8e95f2cfde33f',
       bg:''
  },
  
  {
       id: 188470,
       name: 'Shakthi TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch009/output/index.mpd',
       key: '2449c60d814ee1781f65358a5738fb54',
       kid: '0251a15c2cdf15cbd52edf4bb9f780b3',
       bg:''
  },
  
  {
       id: 163672,
       name: 'TV1',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch010/output/index.mpd',
       key: 'e36984f03c232c8775fa96c31eb678a3',
       kid: '992a7e66e068d554b10f2f10b39dcacb',
       bg:''
  },
  
  {
       id: 733926,
       name: 'Hiru TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch011/output/index.mpd',
       key: 'dac7d0a94d6357221b7712dc865cb44d',
       kid: '3b1958fb98e3a8553490130869d4436a',
       bg:''
  },
  
  {
       id: 599372,
       name: 'TNL',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch012/output/index.mpd',
       key: 'de860ee3485ba7866bd7d17df359f592',
       kid: 'a02a29447436f098bb06abe7cb11c58a',
       bg:''
  },
  
  {
       id: 813924,
       name: 'ART Television',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch013/output/index.mpd',
       key: '173cc4a2811d94c72e1b50be07ade60f',
       kid: 'ce18277648b5aad31cbf282e3534c800',
       bg:''
  },
  
  {
       id: 219127,
       name: 'AdaDerana 24x7',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch014/output/index.mpd',
       key: '14aa0ab231f31647e015368c42f2f7a9',
       kid: 'ed610a537dad45d5258e4121453ec3f1',
       bg:''
  },
  
  {
       id: 345695,
       name: 'Siyatha TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch015/output/index.mpd',
       key: 'acabb77706afd213421c054f65b722db',
       kid: 'da095c049fe7f9b7799ed2dd332f4de9',
       bg:''
  },
  
  {
       id: 733780,
       name: 'Haritha TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch016/output/index.mpd',
       key: '87dd47ce5a56796eae97e5187f6c3ac0',
       kid: '6b06e230a2868bc977cecb14aff18e62',
       bg:''
  },
  
  {
       id: 97247,
       name: 'TV Didula',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch017/output/index.mpd',
       key: '65a62688b4bd4266e9aa729f920f05e1',
       kid: '2e6bcee1bb45fb790773d240b2649c7b',
       bg:''
  },
  
  {
       id: 153780,
       name: 'Ridee TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch018/output/index.mpd',
       key: '131bbc411fe596dddec02cc21ee697d2',
       kid: '250499e3be50d9b921c11d8c7ed8e37a',
       bg:''
  },
  
  {
       id: 760869,
       name: 'Citi Hitz',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch019/output/index.mpd',
       key: '7b40cb47e1e5d1c817818037c75a9836',
       kid: 'e612b9eef991f4922201eb6933f4fc9f',
       bg:''
  },
  
  {
       id: 743138,
       name: 'Supreme TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch020/output/index.mpd',
       key: 'f48cb148397c2f31c5f34ae1b3e144c8',
       kid: '7eb320cf52674feb30d9cd0178072a6a',
       bg:''
  },
  
  {
       id: 842687,
       name: 'Rangiri Sri Lanka',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch021/output/index.mpd',
       key: 'e0b87aafa3e55c15ce84f180c7a339d9',
       kid: '235f5d0a292c6b8b66aee0941ec6966b',
       bg:''
  },
  
  {
       id: 744223,
       name: 'Hi TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch022/output/index.mpd',
       key: 'e1eca0facbdbf97a2ed0cc9eaf6ca566',
       kid: 'bf309f0ad95f5775660d0cf938685764',
       bg:''
  },
  
  {
       id: 226861,
       name: 'Pragna TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch023/output/index.mpd',
       key: '3509585331575d80ef10024638fe2de3',
       kid: 'b244ab0ddc38de333c1543362feda742',
       bg:''
  },
  
  {
       id: 686595,
       name: 'Damsathara TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch024/output/index.mpd',
       key: 'd9e1f3cfd40e44dda40fdb2a95c642b7',
       kid: 'effe505d252d2bb429505708a693786a',
       bg:''
  },
  
  {
       id: 765573,
       name: 'Jaya TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch025/output/index.mpd',
       key: '902ea6a5bfe9658b482b3c2f8cee0125',
       kid: 'f724a1b906b76968fd8e93a6c1adcbe2',
       bg:''
  },
  
  {
       id: 943396,
       name: 'Buddhist TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch026/output/index.mpd',
       key: '4c241694a29ab5ddcce7548ad5943d21',
       kid: 'bc0b3c0aa060a182510aa2e92c2e85cc',
       bg:''
  },
  
  {
       id: 503907,
       name: 'Shraddha TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch027/output/index.mpd',
       key: 'aa46dcc389dbcd6a881e0c61806f47be',
       kid: '1add8b47dc11c0da47d16de5772f8712',
       bg:''
  },
  
  {
       id: 281820,
       name: 'GOD TV / Swarga TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch028/output/index.mpd',
       key: '5af5d0716ee105bd78969d7929c66996',
       kid: '203f29aeb307dcf801b38746982d4344',
       bg:''
  },
  
  {
       id: 390092,
       name: 'EWTN / Verbum',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch029/output/index.mpd',
       key: 'a5f9a8e5920b547dccb33e71f4d93517',
       kid: 'a8b598632e175e20be4430d1a67c1a5a',
       bg:''
  },
  
  {
       id: 432761,
       name: 'Al Jazeera',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch030/output/index.mpd',
       key: 'c4812e0b76ac24a5f434cb8bbf1f0c38',
       kid: '2596470094cb3b50b8f55e4054a358d2',
       bg:''
  },
  
  {
       id: 419361,
       name: 'ABC Australia',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch031/output/index.mpd',
       key: '3eea9904a53c005d74c2ece058dfe82b',
       kid: 'e579f063ca2f2108c4444383feeb7740',
       bg:''
  },
  
  {
       id: 653435,
       name: 'BBC World',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch032/output/index.mpd',
       key: '3f0d883fed415e1a7006bf99e66dfc83',
       kid: 'c22e352d34bc174aee6563d8e130b97a',
       bg:''
  },
  
  {
       id: 84418,
       name: 'CGTN',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch033/output/index.mpd',
       key:'',
       kid: '',
       bg:''
  },
  
  {
       id: 667199,
       name: 'CNN',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch034/output/index.mpd',
       key: '8deb7a35b31b3ad09cc77d54db973edd',
       kid: 'ac4c36d336ed7710bd5a1a82738aa006',
       bg:''
  },
  
  {
       id: 470862,
       name: 'NDTV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch035/output/index.mpd',
       key: '87ec73876219cbcec10e57d1ac0eb0e5',
       kid: 'b29513abdd2fcb3adb3c033efd52005f',
       bg:''
  },
  
  {
       id: 974280,
       name: 'France 24',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch036/output/index.mpd',
       key: 'b3bbd80402f96f4cc89ea590c1dabc16',
       kid: 'a051972b0ef59afbdb90f5383574de02',
       bg:''
  },
  
  {
       id: 177185,
       name: 'EURO News',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch037/output/index.mpd',
       key: '22b8375cde62edf360a491005586bbc8',
       kid: 'c58a42e8a3e75e4e0719cd78a9285b32',
       bg:''
  },
  
  {
       id: 969512,
       name: 'Bloomberg',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch038/output/index.mpd',
       key: 'dffbb56c311502d53ca4aa6b4eb0fd11',
       kid: 'ff0b8c568f6f81bc654b40016f21616b',
       bg:''
  },
  
  {
       id: 784407,
       name: 'CNBC',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch039/output/index.mpd',
       key: '896466630d1237117acc14ef2690fd0d',
       kid: '8600e338f781b71075588c8834f19940',
       bg:''
  },
  
  {
       id: 594084,
       name: 'B4U Music',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch040/output/index.mpd',
       key: '7e99064b1f55088b21bb3c831a3919d1',
       kid: '3eeb72d282b915b687934974c09559f9',
       bg:''
  },
  
  {
       id: 232113,
       name: 'VH1',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch041/output/index.mpd',
       key: '9463ac6df1e816fe3c441d86ee3d08b5',
       kid: '403e38b2dd03b13dc77c351d7ffa1c1b',
       bg:''
  },
  
  {
       id: 112164,
       name: 'Channel C',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch042/output/index.mpd',
       key: 'ea63875af607a8b933828653f2df4cc8',
       kid: 'c851ff1093be8c33e58a42bfd457a404',
       bg:''
  },
  
  {
       id: 485682,
       name: 'Sun Music',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch043/output/index.mpd',
       key: 'a0c7cc8d78d42082af325149dbdd1aab',
       kid: '429857d76d3026b160d1c707e98cb6d4',
       bg:''
  },
  
  {
       id: 675548,
       name: 'Zing TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch044/output/index.mpd',
       key: 'd0ddc29d4119b6beae750cd7beaf000d',
       kid: '5365881d62aedd5433307e17d5a55ce1',
       bg:''
  },
  
  {
       id: 285310,
       name: 'Da Vinci',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch045/output/index.mpd',
       key: '12530ba3a026efb6f434d051265b04b6',
       kid: '9ea92f310afa75d9a4811b9dbe8bb505',
       bg:''
  },
  
  {
       id: 411671,
       name: 'Cheebies',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch046/output/index.mpd',
       key: '2b8dfcc73d86a2959d02cae357f62d6a',
       kid: '973a107547b55b7ca288d93bd56013bb',
       bg:''
  },
  
  {
       id: 681420,
       name: 'Cartoon Network',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch047/output/index.mpd',
       key: 'fe0f260a3014c12b099398fc7149c1e1',
       kid: '2255edb0c35099fc39fc05f4a5ac723c',
       bg:''
  },
  
  {
       id: 313901,
       name: 'A Plus TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch048/output/index.mpd',
       key: '3f23476103abcd2f1fa405b4173cc4d0',
       kid: '7115025de6270ca26f5f78acb79594c0',
       bg:''
  },
  
  {
       id: 963105,
       name: 'Nickelodeon',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch049/output/index.mpd',
       key: '99d326c1e578bc59a2ec166ea1516d79',
       kid: 'f0340c35e78ba096c04b353d4a0b6108',
       bg:''
  },
  
  {
       id: 938966,
       name: 'Baby TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch050/output/index.mpd',
       key: '9fbda902374f47e21cff525e07cb8fb4',
       kid: 'adbe19585c69116407a7c8957695af0a',
       bg:''
  },
  
  {
       id: 620285,
       name: 'Disney Jr',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch051/output/index.mpd',
       key: '69997f4fdc9a097f0a5cbd87afbd7d24',
       kid: '09f81e30aa4c15537573f47cb5202b07',
       bg:''
  },
  
  {
       id: 57746,
       name: 'Nick JR',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch052/output/index.mpd',
       key: '9dd788aa636b777a2796f3293436e9b5',
       kid: 'a9ad0ec5a9f6c1ef2b2b491fd2375a35',
       bg:''
  },
  
  {
       id: 684598,
       name: 'BBC Earth',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch053/output/index.mpd',
       key: '23b580e054b9f7b0a69c5f8f8625a689',
       kid: '6e4e5edfec3bfb04c7048cd0cc117359',
       bg:''
  },
  
  {
       id: 477686,
       name: 'NGC Wild',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch054/output/index.mpd',
       key: '31b8c1b7f14bcc528ad9e386d06c3d94',
       kid: 'a4544bac30066e1a6380c60753901b30',
       bg:''
  },
  
  {
       id: 467842,
       name: 'Animal Planet',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch055/output/index.mpd',
       key: 'f3932be11f8070d61dfc0dc09c78ed6d',
       kid: '2810e4710967572e0c7bbda0f0e66971',
       bg:''
  },
  
  {
       id: 557829,
       name: 'Discovery',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch056/output/index.mpd',
       key: '20b204b63b58380a23e87ceac5289547',
       kid: '29b7e74bd2682baae53b8357993e428d',
       bg:''
  },
  
  {
       id: 696935,
       name: 'Discovery Science',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch057/output/index.mpd',
       key: '98968ad038d5a493054024d18d1889b6',
       kid: 'c2ab84f4c18f92880eadad06744b9b97',
       bg:''
  },
  
  {
       id: 142120,
       name: 'TLC',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch058/output/index.mpd',
       key: '107325f4f675c7a8691217e0e57fd194',
       kid: '5886e8837a437e8796cd66e71a31acab',
       bg:''
  },
  
  {
       id: 394601,
       name: 'NAT Geo',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch059/output/index.mpd',
       key: '1b511b2a4b0e57170422728a5181f56c',
       kid: '9146bd4d5f5089e5ccfbca1edc7b5462',
       bg:''
  },
  
  {
       id: 97252,
       name: 'History TV 18',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch060/output/index.mpd',
       key: 'b04ff2c9e39cdcdcac7bb15d23f8d405',
       kid: '09cf6b5c15fbee993e587cd26149b219',
       bg:''
  },
  
  {
       id: 924264,
       name: 'Travel XP',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch061/output/index.mpd',
       key: '918e7a95665e23a987c07b7448771c6a',
       kid: 'ec45f3e499f6a427004d11799aa5e28a',
       bg:''
  },
  
  {
       id: 975687,
       name: 'HGTV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch062/output/index.mpd',
       key: '4f0ce826162a2324e00b38037c66813a',
       kid: '5681ce57e1b1b01e3294b9f4dda3cd47',
       bg:''
  },
  
  {
       id: 91381,
       name: 'Food Network',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch063/output/index.mpd',
       key: 'e851cebf847b2d391fb34ec15352940b',
       kid: 'a8291de8b8740c7ed710bc2200128557',
       bg:''
  },
  
  {
       id: 354651,
       name: 'Sony Sports 1',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch064/output/index.mpd',
       key: '58349d7f24097936ad0c6b7f693fd822',
       kid: '219f053b480a40ca4a53ec224ef14555',
       bg:''
  },
  
  {
       id: 869663,
       name: 'Ten Cricket',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch065/output/index.mpd',
       key: '63a7fe4e7899ba2bc5bbd4307398f3ff',
       kid: 'abfc480ae7920a3ebd9e99e7b9a0d3e2',
       bg:''
  },
  
  {
       id: 981559,
       name: 'Sony Sports 2',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch066/output/index.mpd',
       key: '234348025ea02d38a05d274e31520cfa',
       kid: '5ead65dfeb91902eb2f06d1b5f5ac25e',
       bg:''
  },
  
  {
       id: 757246,
       name: 'Eurosport',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch067/output/index.mpd',
       key: '43274338b768b62c5a0efcabcbaf3e28',
       kid: 'a908bac4035ab54e3f0a265d7b2478c9',
       bg:''
  },
  
  {
       id: 599747,
       name: 'Star Sports 2',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch069/output/index.mpd',
       key: '3e22bc39d22a7f8c3516f6b58b144668',
       kid: '79b340b2b5f7c3c89951daabde7cdf97',
       bg:''
  },
  
  {
       id: 372043,
       name: 'HBO Signature',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch070/output/index.mpd',
       key: '2330e4780e0d00e2844650fc61398151',
       kid: 'f067e27443af2dc5732d0c0f5fd7d635',
       bg:''
  },
  
  {
       id: 554312,
       name: 'HBO Family',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch071/output/index.mpd',
       key: '81699afb39dccfecf24b5f506a56d01e',
       kid: 'fb1f8d804939c732edc63782d8e33e26',
       bg:''
  },
  
  {
       id: 677956,
       name: '&flix',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch072/output/index.mpd',
       key: '496d0603280e904a223caa65081e6dad',
       kid: '8f762d3b3483516558cbde2450a31177',
       bg:''
  },
  
  {
       id: 702649,
       name: 'HITS Movies',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch073/output/index.mpd',
       key: '23627608eb9d3905e9447253dd9f21a1',
       kid: '65bd7d18b13569e29c0ae7553b934d21',
       bg:''
  },
  
  {
       id: 182561,
       name: 'SONY PIX',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch074/output/index.mpd',
       key: 'a5061952f9ab1e3d562f32bc55125084',
       kid: 'a7631d3ad183aaff6255e85a2e4a6562',
       bg:''
  },
  
  {
       id: 781427,
       name: 'Hits',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch075/output/index.mpd',
       key: 'N/A',
       kid: 'N/A',
       bg:''
  },
  
  {
       id: 35924,
       name: 'ZEE Cafe',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch076/output/index.mpd',
       key: '5bedaf1660f0b28c0d1cb0bec04ee474',
       kid: '2e79d794d56a96616533e053aa41bd06',
       bg:''
  },
  
  {
       id: 419827,
       name: 'WarnerTV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch077/output/index.mpd',
       key: '365453b1e6ae0c59ba7a3fc55970c8d4',
       kid: '18ef8412cdc5d4b84a7d6dd148c6b39b',
       bg:''
  },
  
  {
       id: 411839,
       name: 'AXN',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch078/output/index.mpd',
       key: 'ea109a5399ddada18eb6833acaa27079',
       kid: 'dd77b60f151d46df8f4d0fbe38f3a8a1',
       bg:''
  },
  
  {
       id: 721605,
       name: 'Colors Infinity',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch079/output/index.mpd',
       key: '2413bc9dc5f9fd5a724ebb76d55b5d3b',
       kid: '3690e397abd66f0cb36b6caf0a4d2b62',
       bg:''
  },
  
  {
       id: 547614,
       name: 'Fashion TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch080/output/index.mpd',
       key: '98145ba1d8e2f873ee033ff42af55d76',
       kid: '2b379f74ccd833308dc40e7c35e88fb0',
       bg:''
  },
  
  {
       id: 525536,
       name: 'FOX Life',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch081/output/index.mpd',
       key: 'd4dcb74bca794665aaabfd3cc5dc0b58',
       kid: '1f64dd4075448a8de146e59b3ca2b52a',
       bg:''
  },
  
  {
       id: 439799,
       name: 'Comedy Central',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch082/output/index.mpd',
       key: 'df0cac0fdcf1d9bd5fa3ea73809a5410',
       kid: 'e02f1d5dd475ba0606327aa1c0b986d2',
       bg:''
  },
  
  {
       id: 886940,
       name: 'Arirang TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch083/output/index.mpd',
       key: '54e178a4f9d90a346fa522ecc7273a29',
       kid: 'c5bd577ce6d96db866a854e8d311ab23',
       bg:''
  },
  
  {
       id: 964444,
       name: 'Star Bharat',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch084/output/index.mpd',
       key: 'c245f1e6b6ad077ccf71317fdad84bdf',
       kid: '9f1e451a3d70f4edd8c1e065d10116fe',
       bg:''
  },
  
  {
       id: 46534,
       name: 'SONY Set',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch085/output/index.mpd',
       key: '85bf12c34c885dce04df8acd597cc55a',
       kid: '2880a0f803c857a156804c119b6c2de8',
       bg:''
  },
  
  {
       id: 631077,
       name: 'SET Max',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch086/output/index.mpd',
       key: '47a66fa9d0f50053f727314e9972022b',
       kid: '5b3ff946d2cbc8ccc1ac008496bc37d5',
       bg:''
  },
  
  {
       id: 170794,
       name: 'Star Gold',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch087/output/index.mpd',
       key: '56e1444ac82676e1fd998ef3c99c5781',
       kid: '8a162b02b2e928fc721855a825055ab9',
       bg:''
  },
  
  {
       id: 667378,
       name: 'Colors',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch088/output/index.mpd',
       key: '27f9f4294c8deacb8f50a2cfb372c7c8',
       kid: 'f0dad74ce622feec984a08ed23e8bc03',
       bg:''
  },
  
  {
       id: 649106,
       name: 'Star Plus',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch089/output/index.mpd',
       key: '2f015a4feb702ec4c981abe13980b7dd',
       kid: '48893e5316b416c1cf5f340b30678868',
       bg:''
  },
  
  {
       id: 794702,
       name: 'B4U Movies',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch090/output/index.mpd',
       key: '7fddf5b54f401ffd74a9ec9c0e7c7860',
       kid: 'a342e800e7d9e1751003ff41f299eb0d',
       bg:''
  },
  
  {
       id: 731520,
       name: 'Star Gold Romance',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch091/output/index.mpd',
       key: '13b78754539049a146e0d348e0cf860a',
       kid: '0972df12392bc157c30987f6277d6488',
       bg:''
  },
  
  {
       id: 231916,
       name: 'ZEE Cinema',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch092/output/index.mpd',
       key: '026114dd15259eb5331cb20677a9e92b',
       kid: 'a8a602417affa09d98e024320e5535df',
       bg:''
  },
  
  {
       id: 142092,
       name: 'Colors Tamil',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch093/output/index.mpd',
       key: '783573e74938263c40048da45e00ab3f',
       kid: 'e4963e6cce8bc7c40495ffc282e67c5c',
       bg:''
  },
  
  {
       id: 53871,
       name: 'Sun TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch094/output/index.mpd',
       key: '65eeb9ed9c709a886a30eac33f672b52',
       kid: 'ef6bef909c3f7b644cedfdb288ec3b5b',
       bg:''
  },
  
  {
       id: 705785,
       name: 'KTV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch095/output/index.mpd',
       key: '57ebecba732a0a1408a7a3cf4e4ce8e6',
       kid: '36e3d3ae1013cc749255e771746d148a',
       bg:''
  },
  
  {
       id: 527510,
       name: 'Star Vijay',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch096/output/index.mpd',
       key: '1277ea199f0f8b8ee6942587a59f1460',
       kid: 'bf99d18ae369d49cb8477455ab5beb8e',
       bg:''
  },
  
  {
       id: 910138,
       name: 'Kalaignar TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch097/output/index.mpd',
       key: '594cf0e6ccfe7216ff26cf658839b693',
       kid: 'afd64884e2d2ab5e121208ac8f4eea21',
       bg:''
  },
  
  {
       id: 16960,
       name: 'ZEE Tamil',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch098/output/index.mpd',
       key: '722c73ba260b6cf47f49ae876f189593',
       kid: '0bd2fc447726fba972e8aef463ca1711',
       bg:''
  },
  
  {
       id: 473977,
       name: 'Sirippoli TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch099/output/index.mpd',
       key: '6e234fb64720783687f02a7096f3737d',
       kid: '436f9924c3a48b2c8c513e82028e0b2a',
       bg:''
  },
  
  {
       id: 174849,
       name: 'Guru TV',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch100/output/index.mpd',
       key: '96054bc44793e860075f35c375a4842e',
       kid: 'c650b60a2380a90297165ac312aafc00',
       bg:''
  },
  
  {
       id: 238748,
       name: 'Nenasa Sinhala Grade 1-5',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch101/output/index.mpd',
       key: '9540457565a81f1ead729c15ccddefa4',
       kid: '5d65fd470e6e8bc959334c997d34ff6a',
       bg:''
  },
  
  {
       id: 214110,
       name: 'Nenasa Tamil Grade 1-5',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch102/output/index.mpd',
       key: '2c43f04d5b0a582bc64033ee7a520565',
       kid: '6b72e21202df96599bb2fc91c9cad3f3',
       bg:''
  },
  
  {
       id: 465498,
       name: 'Nenasa Sinhala Grade 6-9',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch103/output/index.mpd',
       key: 'd784cb6bba8a130c303783fe93381f6b',
       kid: 'a3fd8073fe9f0af9845efd2c80747590',
       bg:''
  },
  
  {
       id: 107262,
       name: 'Nenasa Tamil Grade 6-9',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch104/output/index.mpd',
       key: '26fe3073d542ac75c1f17ab74beb7565',
       kid: '219bd8d470a98ab38543b1258d045f7b',
       bg:''
  },
  
  {
       id: 605191,
       name: 'Nenasa Sinhala Grade O/L',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch105/output/index.mpd',
       key: '9e9db2517d99d3c00c2653c5d41ae6da',
       kid: 'd82b0ea4bd0ba68b82a10e0cc955ba9f',
       bg:''
  },
  
  {
       id: 816410,
       name: 'Nenasa Tamil Grade O/L',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch106/output/index.mpd',
       key: '87f315db608c359976b088668a839312',
       kid: '534c2813de30a8c4c953657e8776b8da',
       bg:''
  },
  
  {
       id: 955956,
       name: 'Nenasa Sinhala Grade A/L',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch107/output/index.mpd',
       key: '6500dfee09ba52224c95d16240d51476',
       kid: '0995b5bb85c0e81480aa698e2cf7e019',
       bg:''
  },
  
  {
       id: 114641,
       name: 'Nenasa Tamil Grade A/L',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch108/output/index.mpd',
       key: '16104147a1df4f3a58d0cc19f0b1bece',
       kid: '22638c5ac756363fdcd4cc7ce04189ae',
       bg:''
  },
  
  {
       id: 404720,
       name: 'Discovery HD World',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch109/output/index.mpd',
       key: 'c559f6a5264850b3ab978b869483f137',
       kid: 'c1b4d41a750d55e393ca136e05cfa945',
       bg:''
  },
  
  {
       id: 205456,
       name: 'Animal Planet HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch110/output/index.mpd',
       key: 'cea7a602fa55da46c0985a1fecd867a4',
       kid: 'aa4c1031e5dc2c9bb7035760b498d0c7',
       bg:''
  },
  
  {
       id: 672152,
       name: 'AXN HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch111/output/index.mpd',
       key: 'd6f793ef16083c382b12d33e7a3b9a3f',
       kid: '18041ae9f0f710cc76665dd87186982d',
       bg:''
  },
  
  {
       id: 291678,
       name: 'ROCK Entertainment HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch112/output/index.mpd',
       key: '231d9d76d8aa8b26327ffb89cda93699',
       kid: 'b4f62d9b5dc911695180120368c6a7ae',
       bg:''
  },
  
  {
       id: 339372,
       name: 'Star Movies Select HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch113/output/index.mpd',
       key: '45ba2e80c6608fe7641d0c7e69cea356',
       kid: '667b331ff1d5b48e7b47fd4b565716f9',
       bg:''
  },
  
  {
       id: 760299,
       name: 'HBO HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch114/output/index.mpd',
       key: 'f843004ae56813c6a71ee1a7519cf03c',
       kid: 'ae508359fc9f70ce602094a432b8cacc',
       bg:''
  },
  
  {
       id: 662507,
       name: 'Star Movies HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch115/output/index.mpd',
       key: 'f6ad960cd231beb735920281d78a8968',
       kid: 'f8ea159f0129e7bed05f81eb5288c1e0',
       bg:''
  },
  
  {
       id: 565762,
       name: 'Cinema World HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch116/output/index.mpd',
       key: '36ef32188ed32dd9c0259f0bf3924762',
       kid: 'cb197938c33a1057be063f1e7d7824b8',
       bg:''
  },
  
  {
       id: 527873,
       name: 'CineMax HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch117/output/index.mpd',
       key: 'f4115bb72d9b0900bf6c1a53344e24da',
       kid: '27cb3d1bff668be04ba6f47592879f1d',
       bg:''
  },
  
  {
       id: 319292,
       name: 'HBO Hits HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch118/output/index.mpd',
       key: '6b3b2be7607e4dde12c076d5d095794a',
       kid: '09713e21a670e4b135b79017eed9f0bc',
       bg:''
  },
  
  {
       id: 199629,
       name: 'Sri Dalada Maligawa Telecast',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch119/output/index.mpd',
       key: '5fe87f36627c621439e8242dc9f4bfb3',
       kid: '36b8d1d7098adcae2cb6d150271b5b54',
       bg:''
  },
  
  {
       id: 763087,
       name: 'Star Sports HD 1',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch120/output/index.mpd',
       key: '29cb9bc0170332b0ffab44bbd2d09910',
       kid: 'a521d7d94563c18155c433f85e3c2ed3',
       bg:''
  },
  
  {
       id: 677135,
       name: 'Star S Select HD 1',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch121/output/index.mpd',
       key: 'cba6f642dac4e2489680631e4b7a0af2',
       kid: '1c6db28d2f3a024669ecfcefb1d99a39',
       bg:''
  },
  
  {
       id: 127757,
       name: 'Star S Select HD 2',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch122/output/index.mpd',
       key: '37aab4a5afa952b9eac0c8ec7dcb4f2d',
       kid: 'cc0ce9325bfb912a4addf4404cd10791',
       bg:''
  },
  
  {
       id: 721272,
       name: 'Sony Sports 2 HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch123/output/index.mpd',
       key: '02424c17b4eb9cd19d6f204db874bf55',
       kid: 'a4b0d01bb39006fec2e92033a7262e5b',
       bg:''
  },
  
  {
       id: 610885,
       name: 'Sony Sports 5 HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch124/output/index.mpd',
       key: 'ff4634d4556cf7a8fafd2a3241097185',
       kid: '94e8de80d8d8159a2a92e75dc83ee207',
       bg:''
  },
  
  {
       id: 346708,
       name: 'Premier Sports HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch125/output/index.mpd',
       key: 'b176c76a615bb75c681258706af0f0a6',
       kid: '0ddf587a9cfc3044e2b7e17dbf0072d9',
       bg:''
  },
  
  {
       id: 864716,
       name: 'Papare HD',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch126/output/index.mpd',
       key: 'e1669c99c646fbbe4d4ade8dfe3a3963',
       kid: '01b1bafc996378ec0a7cb804aedca646',
       bg:''
  },
  
  {
       id: 366342,
       name: 'Star Sports 1',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch203/output/index.mpd',
       key: '0cf4899a048134b4344a92480ba8dd14',
       kid: '8408f2b8d80bdd171039c48a10b48b6e',
       bg:''
  },
  
  {
       id: 436398,
       name: 'Star Sports 1',
       link: 'https://bpcdn.dialog.lk/bpk-tv/Ch208/output/index.mpd',
       key: '2e9b53cd33d9d1100000eaa1ddef5af3',
       kid: '945ecd10cbb4ea2cdbca7d836d3cb37b',
       bg:''
  },
  
  
 
 
   ];
  const aaa= parseInt(id);
  const specificId = aaa;
  const playdata = data.find(array => array.id === specificId);
  const linkx = (playdata.link);
  const keyx = (playdata.key);
  const kidx = (playdata.kid);
  const backg = (playdata.bg);

  
  // finding the object whose id is '3'
  useEffect(() => {

   


  //   // Load JWPlayer script dynamically
    const script = document.createElement('script');
    script.src = 'https://ssl.p.jwpcdn.com/player/v/8.8.6/jwplayer.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
  //     // Setup the player
      const player = window.jwplayer('jw_player').setup({
        file: linkx,
        image: backg,
        drm: {
          clearkey: {
            key: keyx,
            keyId: kidx
          }
      }
    });
     
     

      const bumpIt = () => {
        const vol = player.getVolume();
        player.setVolume(vol + 10);
      }
      bumpIt();
    };

  //   // Cleanup on component unmount
    return () => {
      document.head.removeChild(script);
    };
  },[keyx, kidx, linkx, backg]);

  // printing object on the console
  return (
    <div>
     {/* {playdata ? (
        <div>
         <p>Name: {playdata.name}</p>
         </div>
      ) : (
        <p>Array not found</p>
      )} */}
            <div id="jw_player">Loading the player...</div>

    </div>
  );
};

export default VideoPlayer;
