import Header from '../component/header';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Homepage = () => {
  const location = useLocation();
  const a = location.state && location.state.id ? location.state.id : 'hi';

  return (
    <div>
      <Header/>
      <h1 className='name'>hi</h1>
      <h1>Hello {a} and welcome to the home</h1>
      <Link to="/Test1">login</Link>
    </div>
  );
}


export default Homepage;
