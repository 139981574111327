import React from 'react'
import './css/header.css'

export default function header() {
  return (
   
    <div className="headder">
       <div className="headerbody">
      <ul>
      <li><div class='icon'><i class='bx bx-movie-play bx-tada main-color'></i> </div></li>
      <li><div className="headertitle"> GHO<span class="main-color">ST ST</span>REAM</div></li>
        <li><a class="home" href="/">Home</a></li>
        <li><a href="/TV">TV</a></li>
        <li><a href="/Films">Films</a></li>
        <li><a href="/About">About</a></li>
        <li><a href="/Contact">Contact</a></li>
        <li class="right"> <div className="headerprofileicon"><img src="https://gama.getbynder.com/m/9a9fca2709b5e43/original/iQ-delivered-by-clinical-experts-icon.png" alt="Italian Trulli"width="46" height="34"/></div></li>
      </ul>
            
    </div>

    <div className="headerleftmenu">
      <div class="headerleftmenu-dropdown">
      <div class='icon'><i class='bx bx-movie-play bx-tada main-color'></i>   GHO<span class="main-color">ST ST</span>REAM
      </div>
    <div class="headerleftmenu-dropdown-content">
    <ul>
   
    <li><a href="/">Home</a></li>
        <li><a href="/TV">TV</a></li>
        <li><a href="/Films">Films</a></li>
        <li><a href="/About">About</a></li>
        <li><a href="/Contact">Contact</a></li>
    </ul>
    </div>
  </div>  
    </div>





    </div>
   


  
  )
}
